let eventEmitter;
if (window._eventEmitter) {
    eventEmitter = window._eventEmitter
} else {
    eventEmitter = new class {
        constructor() {
            this.event = Object.create(null);
        }
        on(type, listener) {
            this.event[type] ? (this.event[type].push(listener)) : (this.event[type] = [listener]);
            return this;
        }
        once(type, listener) {
            const only = () => {
                listener.apply(this, arguments);
                this.off(type, only);
            }
            this.on(type, only)
            return this
        }
        emit(type, ...args) {
            this.event[type] && this.event[type].forEach(e => e.apply(this, args));
            return this;
        }
        off(type, listenter) {
            if (!listenter) { this.event[type] = null; return this; };
            this.event[type] && this.event[type].splice(this.event[type].indexOf(listenter), 1);
            return this;
        }
    }();
    window._eventEmitter = eventEmitter;
}
export default {
    eventEmitter
}