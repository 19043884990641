import Vue from 'vue'
import ActiveDeactivePostpone from './active-deactive-postpone.vue'

let ActiveDeactivePostponeConstrustor = Vue.extend(ActiveDeactivePostpone);
let instance;

const CardOperation = {
    getInstance(ctx, optType, card_id) {
        optType = Object.assign({}, { isBatchActive: false, mode: 'active',EnableBatch:0 }, optType)
        instance = new ActiveDeactivePostponeConstrustor({ data: { ctx, optType, card_id } }).$mount()
        return instance
    },
    render() {
        document.body.appendChild(instance.$el)
        return instance
    }
}

export default CardOperation