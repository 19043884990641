<template>
  <el-dialog-x-dialog :title="formatTitle" :visible.sync="activeVisible"
    :width="optType.mode !== 'active' ? '650px' : '800px'" append-to-body :close-on-press-escape="false"
    ref="elDialogXdialog">
    <el-form v-if="optType.mode === 'active'" :model="activeForm" :rules="activeFormRule" ref="activeForm"
      label-width="120px" style="max-height:280px;">
      <el-form-item label="业务员名称" prop="salesman">
        <el-input v-model="activeForm.salesman" style="width:400px;" placeholder="请输入业务员名称"></el-input>
      </el-form-item>
      <el-form-item label="所属销售公司" prop="company">
        <el-input v-model="activeForm.company" style="width:400px;" placeholder="请输入所属销售公司"></el-input>
      </el-form-item>
      <template v-if="optType.isBatchActive">
        <template v-for="(item, index) in activeForm.sides">
          <el-form-item :key="index" :label="!index && '卡号区间'">

            <el-input v-model="item.bg" style="width:200px;" placeholder="请输入开始卡号"></el-input>&nbsp;<span
              style="color:#aaa;">——</span>&nbsp;<el-input v-model="item.ed" style="width:200px;"
              placeholder="请输入结束卡号"></el-input>
            <el-button v-if="!index" size="small" type="primary" @click="handleSides(0)"
              style="margin-left:20px;width: 80px;">新增区间
            </el-button>
            <el-button v-else size="small" type="primary" @click="handleSides(1, index)"
              style="margin-left:20px;width: 80px;">删除
            </el-button>

          </el-form-item>
        </template>
      </template>
      <el-form-item style="text-align: right;">
        <el-button size="small" @click="cancle">取消
        </el-button>
        <el-button size="small" type="primary" @click="handleActiveFormConfirm">确定
        </el-button>
      </el-form-item>
    </el-form>
    <el-form v-else :model="expCardsKeyForm" :rules="expCardsKeyFormRule" ref="expCardsKeyForm" label-width="120px">
      <el-form-item label="延长时间" prop="day">
        <el-input-number :controls="false" v-model="expCardsKeyForm.day" :min="1" :max="5000" placeholder="请输入延长天数"
          style="width:350px;">
        </el-input-number>天
      </el-form-item>
      <el-form-item label="">
        提示：要延长的时间将会从设置完保存的那一刻开始计算。
      </el-form-item>
      <el-form-item style="text-align:right;">
        <el-button size="small" @click="cancle">取消
        </el-button>
        <el-button size="small" type="primary" @click="handleExpCardsKeyFormConfirm">确定
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog-x-dialog>
</template>

<script>
import * as API_Card from "@/api/combo-card-shop/card";
import { updateCardsKeyStatus } from "@/api/coupon";
import { format } from "path";
const checkSides = (rule, value, callback) => {
  if (value.some((i) => !i.bg || !i.ed)) {
    callback(new Error("请输入卡号区间"));
  } else {
    callback();
  }
};

export default {
  name: "activeDeactivePostpone",
  data () {
    return {
      formatTitle: '',
      activeVisible: false,
      activeForm: {
        salesman: "",
        company: "",
        sides: [
          {
            bg: "",
            ed: "",
          },
        ],
      },
      ctx: {},
      optType: {
        isBatchActive: false,
        mode: "active",
        EnableBatch: 0
      },
      card_id: null,
      paramsAlive: {
        active: 1,
      },
      paramsDead: {
        active: 0,
      },
      expCardsKeyForm: {
        id: "",
        day: "",
      },
      activeFormRule: {
        salesman: [
          {
            required: true,
            message: "请输入操作业务员",
            trigger: "blur",
          },
        ],
        company: [
          {
            required: true,
            message: "请输入所属销售公司",
            trigger: "blur",
          },
        ],
        sides: [
          {
            required: true,
            message: "请输入卡号区间",
            trigger: "blur",
            validator: checkSides,
          },
        ],
      },
      expCardsKeyFormRule: {
        day: [
          {
            required: true,
            message: "请输入延长天数",
            trigger: "blur",
          },
          { type: 'number', message: '请输入数字', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              // 正整数校验规则
              if (!/^[1-9]\d*$/.test(value)) {
                callback(new Error('延期天数只允许是正整数'))
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
      },
    };
  },
  mounted () {
    if (this.optType.mode !== 'active') {
      this.formatTitle = '延期操作'
    } else if (this.optType.EnableBatch == 1) {
      this.formatTitle = '批量启用'
    } else if (this.optType.EnableBatch == 2) {
      this.formatTitle = '启用操作'
    } else if (this.optType.isBatchActive) {
      this.formatTitle = '区间激活操作'
    } else {
      this.formatTitle = '激活操作'
    }
    this.optType.salesman && (this.activeForm.salesman = this.optType.salesman);
    this.optType.company && (this.activeForm.company = this.optType.company);
    if (this.optType.mode === 'active' && !this.activeForm.salesman && !this.activeForm.company && this.card_id) {
      console.log(this.card_id)
      API_Card.getOneCardTh(this.card_id).then(res => {
        this.activeForm = {
          ...this.activeForm,
          salesman: res.salesman_name,
          company: res.client_name
        }
        console.log(res)
      })
    }
  },
  destroyed () {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
  },
  watch: {
    activeVisible: function (n) {
      if (!n) this.destroy();
    },
  },
  methods: {
    handleSides (type, index) {
      if (type) {
        this.activeForm.sides.splice(index, type);
      } else {
        this.activeForm.sides.splice(this.activeForm.sides.length - 1, type, {
          bg: "",
          ed: "",
        });
      }
    },
    /**
     * 激活
     */
    active () {
      let { salesman, company, sides } = this.activeForm;
      let _params = { ...this.paramsAlive, ...this.ctx };
      // this.optType.isBatchActive && (_params.sides = sides);

      return new Promise((resolve, reject) => {

        if (this.optType.isBatchActive) {
          _params.sides = sides;
          if (_params.sides.every(i => !i.bg && !i.end)) {
            _params.is_all = 1;
          } else {
            _params.is_all = 0;
          }
        }
        API_Card.changeKeyStatus(_params, { salesman, company })
          .then((res) => {
            this.$message.success("激活成功");
            this.destroy();
            resolve();
          })
          .catch(reject);
      });
    },
    /**
     * 启用
     */
    enable () {
      let { salesman, company } = this.activeForm;
      let _params = { ...this.ctx, active_salesman: salesman, company };
      return new Promise((resolve, reject) => {
        updateCardsKeyStatus(_params)
          .then((res) => {
            this.$message.success("启用成功");
            this.destroy();
            resolve();
          })
          .catch(reject);
      });
    },
    /**
     * 禁用
     */
    deactive () {
      let _params = { ...this.paramsDead, ...this.ctx };
      return new Promise((resolve, reject) => {
        API_Card.changeKeyStatus(_params, {})
          .then((res) => {
            this.$message.success("禁用成功");
            resolve();
          })
          .catch(reject);
      });
    },
    /**
     * 延期
     */
    postpone () {
      let _params = { ...this.paramsAlive, ...this.ctx };
      if (this.optType.isBatchActive) {
        _params.sides = sides;
        if (_params.sides.every(i => !i.bg && !i.end)) {
          _params.is_all = 1;
        } else {
          _params.is_all = 0;
        }
      }
      console.log(_params)
      return new Promise((resolve, reject) => {
        this.expCardsKeyForm.id = this.optType.expCardsKeyFormId;
        API_Card.expCardsKey(_params, this.expCardsKeyForm)
          .then(() => {
            this.$message.success("延期成功");
            this.destroy();
            resolve();
          })
          .catch(reject);
      });
    },
    cancle () {
      this.destroy();
    },
    /**
     * 激活表单确认
     */
    handleActiveFormConfirm () {
      this.$refs["activeForm"].validate((valid) => {
        if (valid) {
          if (this.optType.EnableBatch !== 0) {//启用
            this.enable().then(() => {
              _eventEmitter.emit("card-grant-list-active");
            });
          } else {
            this.active().then(() => {//激活
              _eventEmitter.emit("card-grant-list-active");
            });
          }
        } else {
          this.$message.error("表单填写有误");
        }
      });
    },
    /**
     * 延期表单确认
     */
    handleExpCardsKeyFormConfirm () {
      this.$refs["expCardsKeyForm"].validate((valid) => {
        if (valid) {
          this.postpone().then(() => {
            _eventEmitter.emit("card-grant-list-active");
            _eventEmitter.emit("num-exp-list");
          });
        } else {
          this.$message.error("表单填写有误");
        }
      });
    },
    open () {
      this.$refs.elDialogXdialog.$mount();
      this.activeVisible = true;
    },
    close () {
      this.activeVisible = false;
    },
    destroy () {
      this.$destroy(true);
      // this.$el.parentNode.removeChild(this.$el);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type='number'] {
  -moz-appearance: textfield !important;
}
</style>
