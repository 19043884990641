import { saveAs } from 'file-saver';
import * as API_Card from "@/api/combo-card-shop/card";
import cardOperation from './components/active-deactive-postpone'
import EventEmitter from "@/utils/event"

export default {
  data() {
    return {
    }
  },
  computed: {},
  mounted() { },
  methods: {
    /**
     * 生成查询参数
     * @param {*} ctxInfo
     * @returns
     */
    genCtxParams (ctxInfo, _params) {
      let obj={}
      if (_params) {// 如果是批量禁用使用此参数
        obj = {
          ..._params, ids: ctxInfo.idsList, is_all: ctxInfo.is_all
        }     
        if (this.$route.query.active=='0') {
          obj.log_id=this.$route.params.log_id
        } else {
          obj.provide_log_id = this.$route.params.log_id,
          obj.log_id=_params.log_id
        }      
        return obj
      }
      let { keywords, sign, status,card_code_end,card_code_start } = ctxInfo.params
      
      obj = {
        card_id: this.$route.params.card_id,
        ids: ctxInfo.idsList,
        is_all: ctxInfo.is_all,
       
        keywords,
        sign,
        status
      }     
      sides: [
        {
          bg: 11,
          ed: 99,
          prifix: '',
          ed: 0
        }
      ]
      if (card_code_start) {
        // 使用正则表达式匹配英文字母
        const regex = /[a-zA-Z]+/g;
        if (card_code_start.match(regex)!=null) {
          obj['sides[0].prifix'] = card_code_start.match(regex)
          obj['sides[0].bg']=card_code_start.split(regex)[1]
        } else {//纯数字卡密
          obj['sides[0].bg']=card_code_start
        }
        if (card_code_end.match(regex)!=null) {
          obj['sides[0].fix'] = card_code_end.match(regex)
          obj['sides[0].ed']=card_code_end.split(regex)[1]
        } else {
          obj['sides[0].ed']=card_code_end
        }
      }
        if (this.$route.query.active=='0') {
          obj.log_id=this.$route.params.log_id
        } else {
          obj.provide_log_id = this.$route.params.log_id,
          obj.log_id=this.$route.params.log_id
        }
      return obj
    },
    /**
     * 批量激活
     * @param {*} ctxInfo
     */
    cardAliveBatch(ctxInfo) {
      cardOperation.getInstance(this.genCtxParams(ctxInfo), { isBatchActive: true }, this.$route.params.card_id);
      cardOperation.render().open();
    },
    /**
     * 启用
     */
    cardEnable(row) {
      let ctx = {
        card_id: this.$route.params.card_id,
        ids: [row.id],
        operate_all: 0,
        active_status:1
      }
      if (this.$route.query.active=='0') {
        ctx.log_id=this.$route.params.log_id
      } else {
        ctx.provide_log_id = this.$route.params.log_id,
        ctx.log_id=row.log_id
      }
      cardOperation.getInstance(ctx, {EnableBatch:2,salesman: row.active_salesman, company: row.company },this.$route.params.card_id);
      cardOperation.render().open();
    },
    /**
     * 批量启用
     * @param {*} ctxInfo
     */
    cardEnableBatch (ctxInfo, _params) {
      console.log(ctxInfo);
      if(!ctxInfo.is_all&&!ctxInfo.idsList.length){
        this.$message.error('请先勾选要启用的卡密');
        return;
      }
      let ctx = {
        card_id: this.$route.params.card_id,      
        ids: ctxInfo.idsList,
        operate_all: ctxInfo.is_all ? 1 : 0,
        active_status:1
      }
        if (this.$route.query.active=='0') {
          ctx.log_id=this.$route.params.log_id
        } else {
          ctx.provide_log_id = this.$route.params.log_id,
          ctx.log_id=ctxInfo.checkdLogid
        }
      cardOperation.getInstance(ctx, {EnableBatch:1 },this.$route.params.card_id);
      cardOperation.render().open();
    },
    /**
     * 批量禁用
     * @param {*} ctxInfo
     */
    cardDeadBatch(ctxInfo, _params) {
      if(!ctxInfo.is_all&&!ctxInfo.idsList.length){
        this.$message.error('请先勾选要禁用的卡密');
        return;
      }
      this.$confirm(`确定要禁用当前的卡密吗？`, '提示', { type: 'warning' })
        .then(() => {
          cardOperation.getInstance(this.genCtxParams(ctxInfo, _params)).deactive().then(() => {_eventEmitter.emit('card-grant-list-active');_eventEmitter.emit('num-active-list')}).catch((e)=>{console.log(e);});
        })
        .catch((e) => {
          console.log(e);
        });
    },
    /**
     * 激活
     */
    cardAlive(row) {
      let { keywords, sign, status } = this.params
      let ctx = {
        card_id: this.$route.params.card_id,
        ids: [row.id],
        is_all: 0,
        // keywords,
        sign,
        status,
      }
      if (this.$route.query.active=='0') {
        ctx.log_id=this.$route.params.log_id
      } else {
        ctx.provide_log_id = this.$route.params.log_id,
        ctx.log_id=row.log_id
      }
      cardOperation.getInstance(ctx, { salesman: row.active_salesman, company: row.company }, this.$route.params.card_id);
      cardOperation.render().open();
    },
    /**
     * 禁用
     */
    cardDead(row,routerName='') {
      let { keywords, sign, status } = this.params
      let ctx = {
        card_id: this.$route.params.card_id,
        ids: [row.id],
        is_all: 0,
        // keywords,
        sign,
        status,
      }
      if (routerName) {//从卡券列表数字跳转进来的
        ctx.log_id=row.log_id==null?0:row.log_id
      } else {
        if (this.$route.query.active=='0') {
          ctx.log_id=this.$route.params.log_id
        } else {
          ctx.provide_log_id = this.$route.params.log_id,
          ctx.log_id=row.log_id
        }
      }
      this.$confirm(`确定要禁用当前的卡密吗？`, '提示', { type: 'warning' })
        .then(() => {
          cardOperation.getInstance(ctx, {}, this.$route.params.card_id).deactive().then(() => {_eventEmitter.emit('card-grant-list-active');_eventEmitter.emit('num-active-list')});
        })
        .catch(() => {
        });

    },
    /**
     * 延期
     */
    cardWantToLiveMoreDays(row) {
      let { keywords, sign, status } = this.params
      let ctx = {
        card_id: this.$route.params.card_id,
        ids: [row.id],
        is_all: 0,
        keywords,
        sign,
        status
      }
      if (this.$route.query.active=='0') {
        ctx.log_id=this.$route.params.log_id
      } else {
        ctx.provide_log_id = this.$route.params.log_id,
        ctx.log_id=row.log_id
      }
      cardOperation.getInstance(ctx, { mode: 'postpone', expCardsKeyFormId: row.id });
      cardOperation.render().open();
    },
    /**
     * 搜索按钮
     */
    search() {
      this.params.page_no = 1;
      this.Get_TableData();
    },
    /**
     * 分页大小
     * @param {*} val
     */
    handleSizeChange(val) {
      this.params.page_size = val;
      this.Get_TableData();
    },
    /**
     * 当前页码
     * @param {*} val
     */
    handleCurrentChange(val) {
      this.params.page_no = val;
      this.Get_TableData();
    },
    importExpData() {
      this.$refs["excel-upload"].$refs["excel-upload-input"].click();
    },
    /**
     * 导入延期数据
     */
    excelSuccess({ results }) {
      console.log(9999999999999)
      this.importExpCardKeysParams.cards_key_dolist = [];
      results.map((item) => {
        this.importExpCardKeysParams.cards_key_dolist.push({
          card_code: item["卡号"],
          day: item["延期天数"],
        });
      });
      this.submitImportCardKeys();
    },
    /**
     * 表格导入卡密
     */
    submitImportCardKeys() {
      if (!this.importExpCardKeysParams.cards_key_dolist[0]) {
        this.$message.error("导入的数据模板有误，请重新导入");
        return;
      }
      API_Card.importExpKeys(this.importExpCardKeysParams).then((res) => {
        this.importAfter(res, this.importAfterCallback);
      });
    },
    importAfterCallback() {
      _eventEmitter.emit("card-grant-list-active");
      _eventEmitter.emit("num-exp-list");
    },
    /**
     * 导入表格后置处理器
     * @param {*} res
     * @param {*} callback
     */
    importAfter(res, callback) {
      callback && callback();
      const {
        success,
        'false': fail,
        file
      } = res
      if (fail === 0) {
        this.$message.success('操作成功');
      } else {
        let msg = `本次共导入${success + fail}条数据，其中导入成功${success}条，失败${fail}条。是否要下载导入失败的数据表？`
        this.$confirm(msg, '提示', {
          type: 'warning'
        }).then(() => {
          this.save(file)
        });
      }
    },
    save(url) {
      // saveAs(url)
      const a = document.createElement("a");
      a.href = url;
      // a.download = "卡密列表" + Date.now();
      a.click();
    },
    Get_TableData_Wrap(){
      this.idsList=[];
      this.$refs.tableData.$refs.table.clearSelection();
      this.Get_TableData();
    }
  }
}
